.fixed-width-cell {
    width: 100px; /* Set your desired width here */
    word-wrap: break-word;
    white-space: normal;
}

.table-container {
    max-height: 4000px; /* Adjust as needed */
    overflow-y: auto;
    overflow-x: auto;
    border: 1px solid #dee2e6; /* Border to show the container edge */
}
  
/* .table {
    width: 100%;
    border-collapse: separate;
}  */

.thead-sticky {
    position: sticky;
    top: 0;
    background-color: #f8f9fa; /* Bootstrap's default background color */
    z-index: 1; /* Ensures header stays above other content */
}

.fixed-cell-width {
    width: 100px; /* Adjust width as needed */
    
    overflow: hidden;
    /* word-wrap: break-word; */
    /* text-overflow: ellipsis; */
    /* white-space: normal; */
    /* white-space: nowrap; */
}
  