/*///////////////////////////////////////////////////////////////////////////////////

	Theme Name: Seo Company
	Description: The Seo Company v1.0
	Author: Diego Velázquez Rabasa - Templune
	Author Personal Website: http://templune.com
	Author Behance Profile: https://www.behance.net/diegovr7
	Author Dribbble Profile: https://dribbble.com/diegovr7
	Author Envato Profile: https://themeforest.net/user/diegovr7
	Version: 1.0

	External Resources:
	Bootstrap 4
	Google Fonts 
	Ionic Icons

	*This Html Theme is royalty free for use in personal and commercial projects with 
	a couple of restrictions.

	Publications
	You are welcome to republish this Html Theme on condition that you link back to
	https://www.behance.net/gallery/67279127/The-Seo-Company-Free-PSD-Template 
	and you should not provide the assets for direct download from your website.

	Prohibitions
	You do not have rights to redistribute, resell, lease, license, sub-license or offer
	this resource to any third party «as is». If you want to use this resource as a part
	of a product intended to be sold via any marketplace, please contact the author 
	templune@gmail.com of the freebie to get an extended license.

/////////////////////////////////////////////////////////////////////////////////*/

/* /////////////////////////////

	1. Basic Styles
	2. Navbar
	3. Buttons
	4. Hero
	5. Marketing
	6. Testimonials
	7. Pricing
	8. Call to Action
	9. .Footer-home
	10. Media Queries

   //////////////////////////*/

.body-home {
	font-family: 'Roboto', sans-serif;
	color: #3a3f52;

}


.h2-home {
	font-size: 44px;
	font-weight: 700;
}

.h3-home {
	font-size: 24px;
	font-weight: 500;
	margin-bottom: 25px;
}

.h5-home {
	font-size: 16px;
}

p {
	font-size: 14px;
	font-weight: 400;
	letter-spacing: .05em;
	color: #53627C;
}

ul {
	list-style-type: none;
	padding-left: 0px;
}

b {
	font-weight: 700;
}

section {
	padding: 100px 0px;
}

.title-block {
	max-width: 700px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 30px;
	text-align: center;
}

.title-block p {
	font-size: 20px;
	font-weight: 300;
	color: #8da2b5;
	margin-bottom: 0px;
}

.divider {
	padding: 2rem 0 0;
    margin: 2rem 0 0;
    border-top: 1px solid #3c3f45;
}

.divider-light {
	padding: 2rem 0 0;
    margin: 2rem 0 0;
	border-top: 1px solid #edf1f2
}

/* ==============================================
   2. Navbar 
===============================================*/

.custom-navbar {
	background-color: #ffffff!important;
	box-shadow: 0 2px 4px 0 rgba(0,0,0,.09);
	height: 70px;
	max-height: 70px;
}

.navbar-toggler {
	font-size: 38px;
	background-color: transparent;
}

.navbar-toggler:focus {
	text-decoration: none;
	outline: none;
}

.navbar-toggler-icon {
	color: #ffffff;
}

.navbar-collapse {
	background-color: #ffffff;
}

.nav-custom-link {
	font-size: 12px;
	font-weight: 400;
	letter-spacing: .05em;
	margin-top: 5px;
}

.navbar a {
	color: #53627C !important;
}

.nav-right {
	float: right;
}

/* ==============================================
   3. Buttons
===============================================*/

.btn-home {
	font-size: 14px;
	font-weight: 400;
	border-radius: 3px!important;
	box-shadow: 0 2px 3px 0 rgba(0,0,0,.05), 0 1px 2px 0 rgba(0,0,0,.08);
}

.btn-home:hover {
	text-decoration: none;
}

.btn-regular {
	background-color: #0095f7;
	color: #ffffff;
	padding: 10px 25px;
	letter-spacing: .1em;
	margin-top: 25px;
	display: inline-block;
}

.btn-regular:hover {
	background-color: #0191f0;
	color: #ffffff;
}

.btn-demo {
	color: #ffffff;
	background-color: #6772e5;
	letter-spacing: .1em;
	padding: 15px 48px;
	display: inline-block;
	width: 100%;
	margin-top: 20px;
}

.btn-demo:hover {
	color: #ffffff;
	background-color: #636ee1;
}

.btn-demo-small {
	font-size: 12px;
	background-color: #6772e5;
	padding: 1px 4px;
}

.btn-demo-small:hover {
	background-color: #636ee1;
}

.btn-demo-small a {
	color: #ffffff!important;
}

.btn-buy {
	color: #ffffff;
	background-color: #0095f7;
	letter-spacing: .1em;
	padding: 15px 48px;
	display: inline-block;
	width: 100%;
	margin-top: 20px;
}

.btn-buy:hover {
	background-color: #0191f0;
	color: #ffffff;
}

/* ==============================================
   4. Hero
===============================================*/

#hero {
	background-color: #f6f8f9;
}

#hero h1 {
	font-size: 50px;
	font-weight: 300;
}

#hero p {
	font-size: 24px;
    font-weight: 300;
    color: #8da2b5;
    margin-bottom: 0px;
    padding: 0px;
}

#hero span {
	font-weight: 400;
    color: #0095f7;
    text-transform: uppercase;
    letter-spacing: .05em;
}

/* ==============================================
   5. Marketing
===============================================*/

#marketing p {
	font-size: 20px;
    font-weight: 300;
    color: #8da2b5;
    margin-bottom: 0px;
    padding: 0px;
}

#marketing span {
	font-weight: 400;
	color: #0095f7;
	text-transform: uppercase;
	letter-spacing: .05em;
}

.content-box {
	padding: 60px 20px;
}

/* ==============================================
   6. Testimonials
===============================================*/

#testimonials {
	background-color: #f6f8f9;
	border-top: 1px solid #F8F9FB;
	border-bottom: 1px solid #F8F9FB;
}

.testimonial-box {
	background-color: #ffffff;
	box-shadow: 0 0 1px 0 rgba(0,12,32,0.04), 0 10px 16px 0 rgba(10,31,68,0.06);
	border-radius: 20px;
	padding: 20px 30px;
	margin-top: 30px;
}

.testimonial-box h6 {
	font-size: 22px;
	font-weight: 700;
	letter-spacing: .05em;
	margin-top: 5px;
	margin-bottom: 0px;
}

.testimonial-box p {
	font-size: 16px;
	font-weight: 300;
	margin-top: 25px;
	line-height: 1.8;
	padding: 0px;
}

.testimonial-box i {
	color: #ffffff;
}

.testimonial-box small {
	color: #8da2b5;
	display: inline-block;
}

.testimonial-box span {
	font-size: 14px;
	color: #ffffff;
}

.rating {
	background-color: #0095f7;
	padding: 2px 12px;
	border-radius: 50px;
}

.profile-picture {
  display: inline-block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.review-one {
  background-image: url('../../assets/img/home/profile-picture-one.jpg');
}

.personal-info {
	padding: 5px 0 20px 0;
	border-bottom: 1px solid #edf1f2;
}

/* ==============================================
   7. Pricing
===============================================*/

.pricing-box {
	background-color: #ffffff;
	padding: 40px 20px;
	border: 1px solid #F8F9FB;
	border-radius: 6px;
	box-shadow: 0 0 1px 0 rgba(0,12,32,0.04), 0 10px 16px 0 rgba(10,31,68,0.06);
	margin-top: 30px;
}

.pricing-box h3 {
	font-size: 22px;
	font-weight: 300;
	letter-spacing: .1em;
	color: #0095f7;
}

.pricing-box h6 {
	font-size: 60px;
	font-weight: 700;
	color: #3a3f52;
	margin-bottom: 0px;
}

.pricing-box p {
	font-size: 17px;
	margin-top: 15px;
	padding: 0px;
}

.pricing-box ul {
	padding-left: 10px;
}

.pricing-box li {
	font-size: 14px;
	font-weight: 300;
	color: #8da2b5;
	letter-spacing: .1em;
}

.pricing-box span {
	font-weight: 500;
	color: #0095f7;
}

.pricing-box small {
	color: #8da2b5;
	letter-spacing: .2em;
}

.pricing-box i {
	font-size: 20px;
	color: #0095f7;
	margin-right: 10px;
}

.pricing-box .demo {
	color: #6772e5!important;
}

/* ==============================================
   8. Call to Action
===============================================*/

#call-to-action {
	background-image: url('../../assets/img/home/call-to-action.png');
	background-repeat: no-repeat;
	background-size: cover;
	height: 100%;
}

#call-to-action h2 {
	color: #ffffff;
}

#call-to-action p {
	font-size: 20px;
	font-weight: 300;
	color: #ffffff;
	opacity: .8;
	margin-bottom: 40px;
}

#call-to-action .title-block {
	margin-bottom: 0px;
}

#call-to-action .btn-regular {
	background-color: #ffffff!important;
	color: #0095f7!important;
	padding: 15px 45px;
}

#call-to-action .title-block {
	margin-bottom: 0px;
}

/* ==============================================
   9. .Footer-home
===============================================*/

.footer-home {
	background-color: #292c31;
    padding: 60px 0;
    color: #62656a;
}

.footer-home h5 {
	color: #aeb3bd;
	text-transform: uppercase;
	letter-spacing: .1em;
	margin-bottom: 20px;
}

.footer-home p {
	font-size: 12px;
	font-weight: 300;
	color: #62656a;
	text-align: justify;
	padding: 0px;
}

.footer-home li {
	font-size: 14px;
	font-weight: 300;
	letter-spacing: .05em;
	line-height: 1.5;
	margin-bottom: 10px;
}

.footer-home a {
	color: #62656a;
}

.footer-home a:hover {
	color: #0095f7;
	text-decoration: none;
}

.footer-home i {
	font-size: 25px;
	color: #62656a;
	margin-right: 10px;
}

.footer-home i:hover {
	color: #0095f7;
}

.footer-home small {
	color: #62656a;
	float: right;
}

.external-links {
	color: #0095f7;
}

/* ==============================================
   9. Media Queries
===============================================*/

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 
	h2 {
		font-size: 34px!important;
		margin-bottom: 20px;
		line-height: 1.6;
	}
	.title-block h2, p {
		text-align: left;
		padding: 0px 20px;
	}

	#call-to-action h2, p {
		text-align: left!important;
		padding: 0 20px;
	}

	#call-to-action {
		background-image: none;
		background-color: #0095f7;
	}

	.footer-home {
		padding: 60px 25px;
	}
	.footer-home h5 {
		font-size: 18px;
		margin-top: 30px;
	}
	.footer-home li {
		font-size: 16px;
		margin-bottom: 15px;
	}
	.footer-home p {
		font-size: 16px;
	}
	.footer-home i {
		font-size: 30px;
		margin-right: 20px;
	}
	.footer-home small {
		float: left;
		margin-top: 20px;
	}
}

@media (max-width: 768px) { 
	#hero h1 {
		font-size: 34px!important;
		margin-bottom: 20px;
	}
	#hero p {
		font-size: 20px!important;
	}
}

@media (max-width: 991px) { 
	.nav-right {
		float: left;
	}
	.nav-custom-link {
		border-bottom: 1px solid #EEE;
	}
	.navbar-nav{
		width: 100%;
	}
	.navbar-nav .nav-link {
		padding: 20px 0;
		text-align: left;
	}

	.btn-demo-small {
		background-color: #ffffff;
		border: 0px;
		padding: 0px;
		box-shadow: none;

	}
	.btn-demo-small:hover {
		background-color: #ffffff;
		border: 0px;
		padding: 0px;
		box-shadow: none;

	}
	.btn-demo-small a {
		color: #53627C!important;
	}
	.nav-custom-link {
		font-size: 13px;
	}

	.icon-mobile {
		font-size: 18px;
		float: right;
	}

	.navbar-collapse {
		border-bottom: 1px solid #efefef;
	}

	.nav-logo-mobile {
		margin: auto;
		padding-right: 50px;
	}

	.nav-logo-desktop {
		display: none;
	}
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
	.icon-mobile {
		display: none;
	}
	.nav-logo-mobile {
		display: none;
	}
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
	.hero-content {
		margin-top: 100px;
	}
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) { 
	.pricing-box h3 {
		font-size: 17px;
	}
	.pricing-box p {
		font-size: 14px;
	}
	.pricing-box li {
		font-size: 12px;
	}
	.btn-buy {
		font-size: 12px;
		padding: 15px 20px;
	}
	.btn-demo {
		font-size: 12px;
		padding: 15px 20px;
	}
	.profile-picture {
		width: 50px;
		height: 50px;
	}
	.testimonial-box h6 {
		font-size: 14px;
		margin-left: 10px;
	}
	.testimonial-box span {
		font-size: 12px;
	}
	.testimonial-box small {
		font-size: 12px;
		margin-top: 4px;
		margin-left: 10px;
	}
}